<template>
  <div class="hotel-information">
    <!-- <h3 class="sub-title">Furuse & AATS Foundation Award - USD 1,000</h3> -->
    <h3 class="sub-title">“Oriental Rising Star” Award (“东方新星”奖)</h3>
    <div class="container">
      <p>
        To encourage young talents becoming dedicated surgeon-scientists in the field of thoracic and cardiovascular surgery, the organizing committee of “ASCVTS 2024” has established an “Oriental Rising Star” Award:
      </p>
      <p class="text-bold">
        <i>First Prize:</i>
      </p>
      <p>
        <i><u>USD 500</u></i> plus one round-trip economy-class air ticket (from the city of the applicant’s current institution to Wuhan), 4-night stay at the headquarter hotel in Wuhan, invitations for attending all official social events, and free registration for the “ASCVTS 2024”;
      </p>
      <p class="text-bold">
        <i>Second Prize:</i>
      </p>
      <p>
        <i><u>USD 500</u></i> plus 4-night stay at the headquarter hotel in Wuhan, invitations for attending all official social events, and free registration for the “ASCVTS 2024”;
      </p>
      <p class="text-bold">
        <i>Third Prize:</i>
      </p>
      <p>
        4-night stay at the headquarter hotel in Wuhan, invitations for attending all official social events, and free registration for the “ASCVTS 2024”.
      </p>
      <p class="text-bold">
        The applicants must meet the following criteria:
      </p>
      <ul class="disc">
        <li>
          Age no more than 35-year-old;
        </li>
        <li>
          Born and received his/her primary education in an Asian country;
        </li>
        <li>
          Obtained MD, PhD degrees (including current PhD students) from a world-class institution;
        </li>
        <li>
          Proven clinical and academic track records in the field of thoracic and cardiovascular surgery;
        </li>
        <li>
          Recommended by the Chief of his/her current department.
        </li>
        <li>
          Submit his/her CV (including his/her 5 best publications in peer reviewed journals) on or before 26th March 2024.
        </li>
      </ul>
      <p>
        Please sent your CV and recommendation letter by chief of your department as affiliation with the e-mail topic “Oriental Rising Star + name”  to ASCVTS2024WUHAN@hotmail.com. The organizing committee will announce the selection results on or before 15 April 2024 at the “ASCVTS 2024” official website and communicate with the awardees via email. 
      </p>
      <p>
        Each and every awardee will be invited to deliver at least one oral presentation at the ASCVTS 2024.
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.hotel-information {
  .sub-title {
    font-weight: 900;
    font-size: 27px;
    line-height: 44px;
    margin: 30px 0px 20px 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;
  }
  p {
    margin-bottom: 1em;
  }
  .container {
    font-family: "PublicSans Regular";
    font-size: 16px;
    text-align: justify;
    .text-danger {
      color: #dc3545;
    }
    .text-bold {
      font-weight: bold;
      color: #000;
      font-size: 18px;
    }
    .disc {
      margin-bottom: 2em;
      li {
        list-style-type: disc;
        margin-left: 20px;
      }
    }
    ul.dash > li{
      list-style: none;
      &:before {
        display: inline-block;
        content: "-";
        width: 1em;
        margin-left: -1em;
      }
    }
  }
}
</style>
